<template>
  <v-card class="unvergessen-shadow d-flex flex-column" style="cursor: pointer" @click="sendName()">
    <div class="grey3">
      <h3 style="padding: 10px; text-align: center">
        {{ element.name }}
      </h3>
    </div>
    <div style="padding: 10px">
      <v-container>
        <v-row>
          <!-- Ist Umsatz bis heute -->
          <v-col cols="6">
            <div class="title" :class="{ 'success--text': element.is > element.aimToday }">
              {{ element.is }}
            </div>
            <div class="caption">
              Ist-Wert {{ new Date(element.date).toLocaleDateString('de-De', this.dateOptions) }}
            </div>
          </v-col>

          <!-- Plan Umsatz bis heute -->
          <v-col cols="6">
            <div class="title">
              {{ element.aimToday }}
            </div>
            <div class="caption">
              Plan-Wert {{ new Date(element.date).toLocaleDateString('de-De', this.dateOptions) }}
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-container style="">
        <v-row>
          <!-- Abweichung -->
          <v-col cols="6">
            <div class="title" :class="{ 'success--text': element.deviation > 0 }">
              <span v-if="element.deviation > 0">+</span>{{ (element.deviation) }}%
            </div>
            <div class="caption">
              Abweichung Ist-Plan
            </div>
          </v-col>
          <!-- Plan Umsatz bis heute -->
          <v-col cols="6">
            <div class="title">
              {{ element.aim }}
            </div>
            <div class="caption">
              Plan-Wert {{ mapMonth() }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-spacer></v-spacer>
    <!-- Progress -->
      <v-container>
        <v-progress-linear
          class="animated"
          :color="computedColor"
          height="20"
          :value="(element.is / element.aim)*100"
          striped
          :buffer-value="(element.aimToday / element.aim)*100"
          style="border-radius: 10px; border-width: 1px; border-style: solid; border-color: lightgrey"
        ></v-progress-linear>
      </v-container>
  </v-card>
</template>

<script>

export default {
  props: ['element'],
  data () {
    return {
      dateOptions: { day: '2-digit', month: '2-digit', year: 'numeric' }
    }
  },
  methods: {
    sendName () {
      this.$emit('update', this.element.name)
    },
    mapMonth () {
      var month = ''
      switch (new Date(this.element.date).getMonth()) {
        case 0:
          month = 'Januar'
          break
        case 1:
          month = 'Februar'
          break
        case 2:
          month = 'März'
          break
        case 3:
          month = 'April'
          break
        case 4:
          month = 'Mai'
          break
        case 5:
          month = 'Juni'
          break
        case 6:
          month = 'Juli'
          break
        case 7:
          month = 'August'
          break
        case 8:
          month = 'September'
          break
        case 9:
          month = 'Oktober'
          break
        case 10:
          month = 'November'
          break
        case 11:
          month = 'Dezember'
          break
        default:
          month = 'error'
          break
      }
      return month
    }
  },
  computed: {
    computedColor () {
      var color = ''
      var devi = this.element.deviation
      if (devi < -30) {
        color = 'error'
      } else if (devi >= -30 && devi < -10) {
        color = 'yellow darken-2'
      } else if (devi >= -10 && devi < 0) {
        return 'success lighten-1'
      } else if (devi >= 0 && devi < 10) {
        return 'success darken-1'
      } else if (devi >= 10 && devi < 30) {
        return 'success darken-2'
      } else {
        return 'success darken-4'
      }
      return color
    }
  }
}
</script>

<style scoped>
* >>> .v-progress-linear--striped .v-progress-linear__determinate {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

@keyframes progress-bar-stripes {
  0% {
    background-position: 40px 0;
  }

  100% {
    background-position: 0 0;
  }
}
</style>
