<template>
  <v-container>
    <v-row>
      <v-col cols="3" v-for="(el, i) in data" :key="i">
        <unvergessen-category-card
        :element="el"
        v-on:update="goToDetail($event)"
        style="height: 100%"
        ></unvergessen-category-card>
      </v-col>
    </v-row>
    <div v-if="customPeriod && customPeriod.changed === true" v-intersect="updatePeriod()"></div>
  </v-container>
</template>

<script>
import PagesCard from '@/components/statistics/pages/PagesCard.vue'
import axios from 'axios'

export default {
  data () {
    return {
      data: {},
      typeView: ''
    }
  },
  props: ['customPeriod'],
  methods: {
    updatePeriod () {
      var data = {}
      if (this.customPeriod.period) {
        data.period = this.customPeriod.period
        data.id = this.typeView
        axios.post('/statistics/memorialPage/details', data)
          .then(res => {
            this.data = res.data
          })
          .catch(() => {})
      } else {
        data.id = this.typeView
        axios.post('/statistics/memorialPage/details', data)
          .then(res => {
            this.data = res.data
          })
          .catch(() => {})
      }
      this.$emit('update', false)
    },
    goToDetail (event) {
      this.$router
        .push({
          name: 'StatisticsMemorialPageDetail',
          params: { id: event }
        })
        .catch(() => {})
    }
  },
  computed: {
    uid () {
      return this.$route.params.id
    }
  },
  created () {
    axios.post('/statistics/memorialPage/details')
      .then((res) => {
        this.data = res.data
      }).catch()
  },
  components: {
    unvergessenCategoryCard: PagesCard
  }
}
</script>
